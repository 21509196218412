export function getBlockName(keys) {
  if (keys.includes("declaration_date")) {
    return "О декларации";
  } else if (keys.includes("declaration_kind_code")) {
    return "Основная информация";
  } else if (keys.includes("reg_number")) {
    return "Таможенная информация";
  } else if (keys.includes("cargo_route_json")) {
    return "Маршрут";
  } else if (keys.includes("sender_name")) {
    return "Отправитель";
  } else if (keys.includes("recipient_json")) {
    return "Получатель";
  } else if (keys.includes("declarant_json")) {
    return "Декларант";
  } else if (keys.includes("carrier_json")) {
    return "Перевозчик";
  } else if (keys.includes("driver_json")) {
    return "Водитель";
  } else if (keys.includes("filler_json")) {
    return "Представленный";
  } else if (keys.includes("container_indicator")) {
    return "О транспорте";
  } else if (keys.includes("dt_ware_range")) {
    return "Представленные документы";
  } else if (keys.includes("tn_ved")) {
    return "Товар";
  } else if (keys.includes("invoice_currency_letter")) {
    return "Общие хар-ки товаров";
  } else if (keys.includes("payer_json")) {
    return "Лицо, ответственное за финансовое урегулирование";
  } else if (keys.includes("including_costs_json")) {
    return "Включение в стоимость";
  }
  {
    return "БЛОК НЕ ЗАРЕГИСТРИРОВАН";
  }
}
